<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />

    <b-row>
      <b-col
        cols="12"
        sm="6"
      >
        <router-link :to="{name: 'disbursement.pending-transactions'}">
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between">
                <div>
                  <h2>{{ request_pending }}</h2>
                  <p class="fontsize-sm m-0">
                    Pending Transactions (s)
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                  <i class="ri-inbox-fill" /></div>
              </div>
            </template>
          </iq-card>
        </router-link>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { DisbursementDashboardService } from '@/services'

export default {
  name: 'DisbursementDashboard',

  middleware: ['auth', 'disbursement'],

  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  data () {
    return {
      request_pending: 0
    }
  },

  mounted () {
    core.index()
    this.fetchData()
  },

  methods: {
    async fetchData () {
      await DisbursementDashboardService.get().then(({ data }) => {
        this.request_pending = data.request_pending
      })
    }
  }
}
</script>
